import React, { useState } from 'react'
import { Button } from '@chakra-ui/react'
import { FormControl, FormErrorMessage, Select } from '@chakra-ui/react'
import CustomForm from './customForm'

const AddConvo = () => {

    const [formData, setFormData] = useState({})
    const [selectData, setSelectData] = useState({ variable: '' })
    const [errors, setErrors] = useState({})

    // const handleChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({ ...formData, [name]: value });
    // };

    const validateForm = (data) => {
        let newErrors = {}

        formFileds.forEach((field) => {
            if (!data[field.name]) {
                newErrors[field.name] = `${field.label} is required`
            }
        })
        if (!data.variable) {
            newErrors.variable = 'Variable Type is Required'
        }

        return newErrors
    }


    const handleChange = (name, value) => {
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors({ ...errors, [name]: '' })
    };

    const handleSelect = (e) => {
        const { name, value } = e.target;
        setSelectData(prevData => ({
          ...prevData,
          [name]: value,
        }));
        setErrors({...errors, [name] : ''})
      };

    const handleSubmit = (e) => {
        e.preventDefault()
        const fullForm = { ...formData, ...selectData };
        const newErrors = validateForm(fullForm)
        setErrors(newErrors)
        console.log('Form Submitted:', fullForm)
    }

    const formFileds = [
        { name: 'name', label: 'Name of the Form/Questionnaire', placeholder: 'E.g Customer Opinion on Product X', type: 'text' },
        { name: 'description', label: 'Detailed Description of What This Form is Meant For', placeholder: 'E.g This survey is for ...', type: 'text' },
        { name: 'prompts', label: 'Form Prompts to Sender to Fill', placeholder: 'E.g Book Train TR29018313', type: 'text' },
        { name: 'times', label: 'Number of Times Sender is Allowed to Fill This Form ', placeholder: 'E.g 1 0r 9999 for mutiple all', type: 'text' },
    ]

    return (

        <div className="bg-white rounded-lg shadow-md p-6 mt-4">

            <div className='ml-3'>

                <h2 className='text-2xl font-semibold mb-5'>Add A Questionnaire/Survey</h2>

                <form onSubmit={handleSubmit}>

                    <CustomForm
                        formFields={formFileds}
                        onChange={handleChange}
                        onSubmit={handleSubmit}
                        formData={formData}
                        errors={errors}
                    />

                    <FormControl isInvalid={errors.variable}>
                        <Select
                            name='variable'
                            value={selectData.variable}
                            onChange={handleSelect}
                            iconColor='transparent'
                            placeholder='Select Form Type'
                            className='p-2 w-full md:w-1/2 lg:w-3/12 border border-indigo-500 focus:outline-indigo-600 rounded-lg bg-white mb-7'>
                            <option value='Form'>Form</option>
                            <option value='Questionnaire'>Questionnaire</option>
                            <option value='Survey'>Survey</option>
                            <option value='Identity Verification'>Identity Verification</option>
                            <option value='Support Questions'>Support Questions</option>
                            <option value='Feedback Form'>Feedback Form</option>
                            <option value='Interview Questions'>Interview Questions</option>
                            <option value='Booking Form'>Booking Form</option>
                            <option value='Account Verification'>Account Verification</option>
                        </Select>
                        <FormErrorMessage className="text-red-600 text-lg mb-8">{errors.variable}</FormErrorMessage>
                    </FormControl>



                    <Button
                        type='submit'
                        className='bg-indigo-200 py-2 px-6 text-lg rounded-lg hover:bg-indigo-500'>
                        Save Questionnaire
                    </Button>

                </form>

            </div>


        </div>
    )
}

export default AddConvo