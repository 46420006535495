import React, { useEffect, useRef, useState } from 'react';
import backgroundImageHome from './components/background.jpg';
import headframeimage from './components/headframe.png';
import aboutus from './components/about-img.png';
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import socialApps from './components/social-apps.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NavBar from './components/navbar';
import TopBar from './components/topBar';
import Footer from './components/Footer';
import { faHeadset, faPuzzlePiece, faRobot } from '@fortawesome/free-solid-svg-icons';
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import AnimationRevealPage from "./helpers/AnimationRevealPage.js";
import Features from "./components/features/feature.jsx";
import FAQ from "./components/faqs/SingleCol.jsx";


const LandingPage = () => {
  const firstDivRef = useRef(null);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [displayText, setDisplayText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [speed, setSpeed] = useState(150);
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      const particles = [];
      const particleCount = 100;
      const particleSize = 2;
      const particleSpeed = 0.5;
      const lineDistance = 100;
      const particleColor = 'rgba(255, 255, 255, 0.8)';
      const cursor = { x: canvas.width / 2, y: canvas.height / 2 };

      const resizeCanvas = () => {
        const heroSection = canvas.parentElement;
        canvas.width = heroSection.offsetWidth;
        canvas.height = heroSection.offsetHeight;
      };

      window.addEventListener('resize', resizeCanvas);
      resizeCanvas();

      const updateCursor = (e) => {
        cursor.x = e.clientX;
        cursor.y = e.clientY;
      };

      window.addEventListener('mousemove', updateCursor);

      for (let i = 0; i < particleCount; i++) {
        particles.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          dx: (Math.random() - 0.5) * particleSpeed,
          dy: (Math.random() - 0.5) * particleSpeed,
        });
      }

      const drawLines = () => {
        for (let i = 0; i < particles.length; i++) {
          for (let j = i + 1; j < particles.length; j++) {
            const distanceX = particles[i].x - particles[j].x;
            const distanceY = particles[i].y - particles[j].y;
            const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

            if (distance < lineDistance) {
              ctx.beginPath();
              ctx.strokeStyle = particleColor;
              ctx.lineWidth = 0.5;
              ctx.moveTo(particles[i].x, particles[i].y);
              ctx.lineTo(particles[j].x, particles[j].y);
              ctx.stroke();
              ctx.closePath();
            }
          }

          const distanceX = particles[i].x - cursor.x;
          const distanceY = particles[i].y - cursor.y;
          const distanceToCursor = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

          if (distanceToCursor < lineDistance) {
            ctx.beginPath();
            ctx.strokeStyle = particleColor;
            ctx.lineWidth = 0.5;
            ctx.moveTo(particles[i].x, particles[i].y);
            ctx.lineTo(cursor.x, cursor.y);
            ctx.stroke();
            ctx.closePath();
          }
        }
      };

      const animate = () => {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        particles.forEach((particle) => {
          particle.x += particle.dx;
          particle.y += particle.dy;

          if (particle.x < 0 || particle.x > canvas.width) particle.dx *= -1;
          if (particle.y < 0 || particle.y > canvas.height) particle.dy *= -1;

          ctx.fillStyle = particleColor;
          ctx.beginPath();
          ctx.arc(particle.x, particle.y, particleSize, 0, Math.PI * 2);
          ctx.fill();
        });

        drawLines();
        requestAnimationFrame(animate);
      };

      animate();

      return () => {
        window.removeEventListener('resize', resizeCanvas);
        window.removeEventListener('mousemove', updateCursor);
      };
    }
  }, []);

  useEffect(() => {
    const words = ['Cross-Industry', 'Multi-Tenancy', 'Cross-Platform'];
    const animations = ['starryNight', 'gradientWave', 'particleNetwork', 'fireflies'];
    const storedIndex = localStorage.getItem('backgroundAnimationIndex');
    const newIndex = storedIndex ? (parseInt(storedIndex) + 1) % animations.length : 0;
    localStorage.setItem('backgroundAnimationIndex', newIndex.toString());
    const handleTyping = () => {
      const currentWord = words[currentWordIndex];
      if (isDeleting) {
        setDisplayText(currentWord.substring(0, displayText.length - 1));
        setSpeed(50);
      } else {
        setDisplayText(currentWord.substring(0, displayText.length + 1));
        setSpeed(150);
      }

      if (!isDeleting && displayText === currentWord) {
        setTimeout(() => setIsDeleting(true), 1000);
      } else if (isDeleting && displayText === '') {
        setIsDeleting(false);
        setCurrentWordIndex((prev) => (prev + 1) % words.length);
      }
    };

    const typingTimeout = setTimeout(handleTyping, speed);
    return () => clearTimeout(typingTimeout);
  }, [displayText, isDeleting, currentWordIndex, speed]);

  const HighlightedText = styled.span`
  @apply text-red-500;
`;

  return (
    <div className="w-full h-full flex flex-col">
      {/* TopBar */}
      <div>
        <TopBar transparent />
      </div>

      {/* Hero Section */}
      <div
        ref={firstDivRef}
        className="relative h-[80vh] flex  flex-col bg-cover bg-center"
        style={{ backgroundImage: `url(${backgroundImageHome})`, position: 'relative', zIndex: 1 }}>
        <canvas
          ref={canvasRef}
          className="absolute inset-0 z-0 hidden md:block"
        />

        <div className='-mt-2'>
          <NavBar />
        </div>
        <div
          style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, zIndex: 2, opacity: 0.6, pointerEvents: 'none', }}>
        </div>
        <div className="w-full py-10">
          <div className="relative flex flex-col px-6 sm:px-10 mx-auto space-y-5 md:w-3/4">
            <div className="max-w-4xl mx-auto text-center flex flex-col items-center justify-center" style={{ position: 'relative', zIndex: 3 }}>
              <h1 className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-extrabold mb-6" style={{ background: 'linear-gradient(90deg, #f1b031, #8a3c05)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent' }}>
                Qual-Bots International
              </h1>
              <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-gray-700 mb-4">
                Your Ultimate AI-Powered Solution:
              </h2>
              <h3 className="text-xl sm:text-2xl md:text-3xl font-bold text-white mb-8">
                {displayText}
                <span className="blinking-cursor"></span>
              </h3>
            </div>
          </div>
        </div>


        <img
          src={headframeimage}
          alt="Headframe"
          className="absolute top-1/2 left-14 transform -translate-y-1/2 max-w-full md:max-w-50 lg:max-w-200 max-h-full md:max-h-140 lg:max-h-200 hidden md:block"
        />
        {/* <img
          src={socialApps}
          alt="Social Apps"
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
          style={{
            width: `${imageSize}px`,
            height: `${imageSize}px`,
            objectFit: 'contain', 
          }} max-w-screen-xl mx-auto
        /> */}
      </div>

      {/* first three boxes */}
      <section className="flex flex-col items-center relative z-20 bg-transparent -mt-36">
        <div className="container px-4">
          <div className="flex flex-wrap">
            <div className="w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg transition-transform transform hover:scale-105 hover:shadow-2xl">
                <div className="px-4 py-5 flex-auto">
                  <div className="p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-[#0b1437] text-white transition-transform transform hover:scale-110">
                    <FontAwesomeIcon icon={faRobot} className="flex" />
                  </div>
                  <h6 className="text-2xl font-semibold">
                    AI-Powered Intelligence
                  </h6>
                  <p className="mt-2 mb-4 text-gray-700">
                    Leverage cutting-edge AI to engage customers in real-time with the efficiency of a human, ensuring every interaction is personalized and precise. Our advanced AI algorithms analyze customer behavior and preferences, allowing you to deliver tailored responses that meet their specific needs. We make each engagement as seamless and effective as possible.
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg transition-transform transform hover:scale-105 hover:shadow-2xl">
                <div className="px-4 py-5 flex-auto">
                  <div className="p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-[#0b1437] text-white transition-transform transform hover:scale-110">
                    <FontAwesomeIcon icon={faPuzzlePiece} className="flex" />
                  </div>
                  <h6 className="text-2xl font-semibold">
                    Seamless Integration
                  </h6>
                  <p className="mt-2 mb-4 text-gray-700">
                    Our platform ensures a smooth and efficient integration process with all major networks and essential business systems. You can effortlessly sync data, streamline workflows, and enhance your digital presence without any hassle, allowing you to focus on what matters most to your business.
                  </p>
                </div>
              </div>
            </div>

            <div className="w-full md:w-4/12 px-4 text-center">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg transition-transform transform hover:scale-105 hover:shadow-2xl">
                <div className="px-4 py-5 flex-auto">
                  <div className="p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-[#0b1437] text-white transition-transform transform hover:scale-110">
                    <FontAwesomeIcon icon={faHeadset} className="flex" />
                  </div>
                  <h6 className="text-2xl font-semibold">
                    24/7 Customer Service
                  </h6>
                  <p className="mt-2 mb-4 text-gray-700">
                    QualBot is always on, delivering reliable customer service around the clock. Whether it's day or night, our AI-powered bot ensures that your customers receive prompt assistance, addressing their needs and queries without delay. We help enhances customer satisfaction and strengthens trust in your brand, knowing that help is just a message away, anytime they need it.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* We are living in the Future! */}
      <section className="relative overflow-hidden bg-transparent">
        <div className="relative h-[80vh] flex items-center justify-center bg-cover bg-center">

          {/* Background Gradients */}
          <div className="absolute top-0 border-none left-0 w-5 h-5 bg-gradient-to-br from-yellow-300 to-[#ab6113] rounded-full blur-4xl opacity-80 dark:opacity-40 animate-bounce-around" style={{ border: 'none' }}></div>
          <div className="absolute bottom-0 right-0 w-80 h-80 bg-gradient-to-bl from-[#0b1437] to-[#054d63] rounded-full blur-3xl opacity-50 dark:opacity-40" style={{ border: 'none' }}></div>
          <div className="absolute top-0 right-0 w-6 h-6 bg-gradient-to-bl from-[#9f540e] to-[#0b1437] rounded-full dark:opacity-40 animate-move-down" style={{ border: 'none' }}></div>

          {/* Background Animation */}
          <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
            <canvas id="particleCanvas" width="100%" height="100%"></canvas>
          </div>

          {/* Content */}
          <div className="relative max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16"
            >
              <div className="sm:text-lg dark:text-gray-300">
                <motion.h2
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1, delay: 0.5 }}
                  className="mb-8 text-4xl md:text-4xl font-extrabold tracking-tight dark:text-white"
                  style={{
                    background: 'linear-gradient(90deg, #f1b031, #8a3c05)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                  }}
                >
                  We are living in the Future!
                </motion.h2>
                <h4 className="text-2xl md:text-4xl font-semibold mb-8">Start your AI go journey with us!</h4>
                <p className="mb-8 text-gray-700 lg:text-xl">
                  QualBots are AI-powered chatbots that understand and respond in multiple languages. We excel in customer engagement, support, and handling bookings for events or transportation. Seamlessly integrating with all major social media platforms and your core business system, QualBots deliver 24/7 customer service with the efficiency and responsiveness of a human employee.
                </p>
                <div className="flex justify-center md:justify-start space-x-8 mb-6">
                  <div>
                    <h3 className="text-3xl font-semibold">
                      <CountUp start={0} end={5000} duration={3} separator="," />
                    </h3>
                    <p className="text-gray-400">Clients</p>
                  </div>
                  <div>
                    <h3 className="text-3xl font-semibold">
                      <CountUp start={0} end={10000} duration={3} separator="," />
                    </h3>
                    <p className="text-gray-400">Projects</p>
                  </div>
                  <div>
                    <h3 className="text-3xl font-semibold">
                      <CountUp start={0} end={2} duration={3} />
                    </h3>
                    <p className="text-gray-400">Years</p>
                  </div>
                </div>
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="px-6 py-2 bg-[#0b1437] text-white hover:bg-[#f0a500] hover:text-[#0b1437] rounded-full font-medium"
                >
                  Read More
                </motion.button>
              </div>
              <motion.div
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="flex justify-center"
              >
                <img
                  src={aboutus}
                  alt="AI and Future"
                  className="w-full md:w-1/2 lg:w-full object-cover rounded-md"
                />
              </motion.div>
            </motion.div>
          </div>
        </div>
      </section>


      {/* Features */}
      <AnimationRevealPage>
        <motion.h2
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          className="text-4xl md:text-4xl font-extrabold tracking-tight dark:text-white text-center"
          style={{
            background: 'linear-gradient(90deg, #f1b031, #8a3c05)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Our Solutions
        </motion.h2>
        <Features />
      </AnimationRevealPage>

      {/* Testimonials and Success Stories */}
      <section className="relative py-20 bg-white">
        <div className="relative flex flex-col w-full px-10 mx-auto space-y-5 md:w-3/4">
          <div className="flex flex-col items-center space-y-2">
            <motion.h2
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1, delay: 0.5 }}
              className="text-4xl md:text-4xl font-extrabold tracking-tight dark:text-white text-center"
              style={{
                background: 'linear-gradient(90deg, #f1b031, #8a3c05)',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
              }}
            >
              Success Stories
            </motion.h2>
            <p className="text-gray-700">Hear from our satisfied clients who have transformed their businesses with QualBot.</p>
          </div>

          {/* Slider */}
          <div className="mt-10">
            <Slider
              // dots={true}
              infinite={true}
              // speed={500}
              slidesToShow={2}
              slidesToScroll={1}
              autoplay={true}
              autoplaySpeed={2000}
              arrows={true}
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]}
            >
              {/* Testimonial 1 */}
              <div className="w-full p-4">
                <div className="p-6 bg-white shadow-lg rounded-xl flex flex-col justify-between h-[300px]"> {/* Set a fixed height */}
                  <p className="mb-4 text-xl font-bold text-gray-700">"Increased Customer Satisfaction"</p>
                  <p className="text-gray-700">
                    Thanks to QualBot, our response times have improved, and customer satisfaction rates have soared!
                  </p>
                  <p className="mt-6 font-medium">- Kinyua</p>
                </div>
              </div>

              {/* Testimonial 2 */}
              <div className="w-full p-4">
                <div className="p-6 bg-white shadow-lg rounded-xl flex flex-col justify-between h-[300px]"> {/* Set a fixed height */}
                  <p className="mb-4 text-xl font-bold text-gray-700">"Seamless Integration"</p>
                  <p className="text-gray-700">
                    QualBot’s seamless integration into our existing systems made it the ideal choice for our company.
                  </p>
                  <p className="mt-6 font-medium">- Amare</p>
                </div>
              </div>

              {/* Testimonial 3 */}
              <div className="w-full p-4">
                <div className="p-6 bg-white shadow-lg rounded-xl flex flex-col justify-between h-[300px]"> {/* Set a fixed height */}
                  <p className="mb-4 text-xl font-bold text-gray-700">"Empowered Our Support Team"</p>
                  <p className="text-gray-700">
                    With QualBot, our support team has become more efficient, allowing them to focus on more complex tasks.
                  </p>
                  <p className="mt-6 font-medium">- Harun</p>
                </div>
              </div>

              {/* Testimonial 4 */}
              <div className="w-full p-4">
                <div className="p-6 bg-white shadow-lg rounded-xl flex flex-col justify-between h-[300px]"> {/* Set a fixed height */}
                  <p className="mb-4 text-xl font-bold text-gray-700">"Revolutionized Our Workflow"</p>
                  <p className="text-gray-500">
                    QualBot has completely transformed our workflow. Tasks that used to take hours are now automated, saving us time and resources.
                  </p>
                  <p className="mt-6 font-medium">- John</p>
                </div>
              </div>

              {/* Testimonial 5 */}
              <div className="w-full p-4">
                <div className="p-6 bg-white shadow-lg rounded-xl flex flex-col justify-between h-[300px]"> {/* Set a fixed height */}
                  <p className="mb-4 text-xl font-bold text-gray-700">"Enhanced Lead Generation"</p>
                  <p className="text-gray-500">
                    With QualBot, we've seen a noticeable increase in high-quality leads. The bot interacts with potential customers effortlessly.
                  </p>
                  <p className="mt-6 font-medium">- Ashley-kenya</p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>


      {/*  FAQ Section*/}
      <AnimationRevealPage>
        <motion.h2
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          className="text-2xl md:text-4xl font-extrabold tracking-tight dark:text-white text-center"
          style={{
            background: 'linear-gradient(90deg, #f1b031, #8a3c05)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          FAQS
        </motion.h2>
        <h4 className="text-3xl md:text-4xl text-center font-semibold">Got Questions, we have Answers! </h4>
        <FAQ
          heading={
            <>
              You have <HighlightedText>Questions ?</HighlightedText>
            </>
          }>
        </FAQ>
      </AnimationRevealPage>

      {/*Contact section*/}
      <section className="pb-20 relative block bg-white">
        <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
          <div className="flex flex-wrap text-center justify-center">
            <div className="w-full lg:w-6/12 px-4">
              <h2 className="text-3xl md:text-4xl font-extrabold mb-6" style={{ background: 'linear-gradient(90deg, #f1b031, #8a3c05)', WebkitBackgroundClip: 'text', WebkitTextFillColor: 'transparent', }}>
                Elevate your Business
              </h2>
              <p className="mb-8 text-gray-700 lg:text-xl">
                AI and bots are revolutionizing operational efficiency across industries. Embrace the future with our innovative solutions and stay ahead in the competitive landscape.
              </p>
            </div>
          </div>
          <div className="flex flex-wrap mt-12 justify-center">
            <div className="w-full lg:w-3/12 px-4 text-center">
              <div className="text-white p-3 w-12 h-12 shadow-lg rounded-full bg-[#b97119]  inline-flex items-center justify-center">
                <FontAwesomeIcon icon={faRobot} className="flex" />
              </div>
              <h6 className="text-xl mt-5 font-semibold">
                Excellent Services
              </h6>
              <p className="mt-2 mb-4 text-gray-700">
                We provide tailored AI-driven solutions that integrate seamlessly into your existing processes. From personalized customer support to data-driven decision-making, our services are designed to ensure your business runs smoothly and efficiently.
              </p>
            </div>
            <div className="w-full lg:w-3/12 px-4 text-center">
              <div className="text-white p-3 w-12 h-12 shadow-lg rounded-full bg-[#b97119] inline-flex items-center justify-center">
                <FontAwesomeIcon icon={faRobot} className="flex" />
              </div>
              <h5 className="text-xl mt-5 font-semibold">
                Grow your market
              </h5>
              <p className="mt-2 mb-4 text-gray-700">
                Unlock new opportunities with AI-powered insights that help you understand and reach your target audience more effectively. With our tools, you can optimize your strategies, expand your reach, and tap into untapped markets effortlessly.
              </p>
            </div>
            <div className="w-full lg:w-3/12 px-4 text-center">
              <div className="text-white p-3 w-12 h-12 shadow-lg rounded-full bg-[#b97119] inline-flex items-center justify-center">
                <FontAwesomeIcon icon={faRobot} className="flex" />
              </div>
              <h5 className="text-xl mt-5 font-semibold">
                Launch time
              </h5>
              <p className="mt-2 mb-4 text-gray-700">
                Ready to take the leap into the future? Our scalable AI solutions can help you launch faster, work smarter, and outperform the competition. Don't just keep up—set the pace and lead your industry into the next era of innovation.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="relative block py-24 lg:pt-0">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-center lg:-mt-64 -mt-48">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-[#e9ecef]">
                <div className="flex-auto p-5 lg:p-10">
                  <h4 className="text-2xl font-semibold">
                    Need a Demo?
                  </h4>
                  <p className="leading-relaxed mt-1 mb-4 text-gray-800">
                    Complete this form and we will get back to you in 24 hours.
                  </p>
                  <div className="relative w-full mb-3 mt-8">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="full-name"
                    >
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Full Name"
                      style={{ transition: "all .15s ease" }}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Email"
                      style={{ transition: "all .15s ease" }}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-gray-700 text-xs font-bold mb-2"
                      htmlFor="message"
                    >
                      Message
                    </label>
                    <textarea
                      rows="4"
                      cols="80"
                      className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                      placeholder="Type a message..."
                    />
                  </div>
                  <div className="text-center mt-6">
                    <button
                      className="bg-[#0b1437] text-white active:bg-[#0b1437] hover:bg-[#f0a500] hover:text-[#0b1437] text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                      type="button"
                      style={{ transition: "all .15s ease" }}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LandingPage;


