import React from "react";
import { FaPhone } from 'react-icons/fa';
import { LuHome, LuMessageSquare } from 'react-icons/lu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faFacebook, faInstagram, faYoutube, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import qualbotTextImage from '../images/Qualbots-logo.png';

export default function Footer() {
  return (
    <footer className="relative bg-[gray-300] pt-8 pb-6">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
        <div className="w-full lg:w-2/12 px-4">
        <img
          src={qualbotTextImage}
          alt="QualBot logo"
          className="logo w-80 md:w-65 h-24 md:h-28 object-cover object-center"
        />
        </div>
          <div className="w-full lg:w-4/12 px-4 text-gray-700">
            <h4 className="text-3xl font-semibold">
              Let's keep in touch!
            </h4>
            <p className="flex items-center mt-1"> <FaPhone /> <span className="ml-2">+254705583722</span></p>
            <p className="flex items-center mt-1"> <LuMessageSquare /> <span className="ml-2">info@qualbots-intl.com</span> </p>
            <p className="flex items-center mt-1"> <LuHome /> <span className="ml-2">KOFISI Karen, 45 Salaam Building, Karen Road. KOFISI Africa</span> </p>
            
            <div className="mt-6">
              <button
                className="text-blue-400 shadow-lg font-normal h-5 w-5 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
              >
                <FontAwesomeIcon icon={faTwitter} className="flex" />
              </button>
              <button
                className="text-blue-600 shadow-lg font-normal h-5 w-5 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
              >
                <FontAwesomeIcon icon={faFacebook} className="flex" />
              </button>
              <button
                className="text-pink-500 shadow-lg font-normal h-5 w-5 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
              >
                <FontAwesomeIcon icon={faYoutube} className="flex" />
              </button>
              <button
                className="text-pink-500 shadow-lg font-normal h-5 w-5 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
              >
                <FontAwesomeIcon icon={faInstagram} className="flex" />
              </button>
              <button
                className="text-blue-700 shadow-lg font-normal h-5 w-5 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                type="button"
              >
                <FontAwesomeIcon icon={faLinkedin} className="flex" />
              </button>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">
              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                  Useful Links
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="https://www.creative-tim.com/presentation">About Us
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="https://blog.creative-tim.com">Products
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="https://www.github.com/creativetimofficial">Work With Us
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="https://www.creative-tim.com/bootstrap-themes/free">Careers
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                  Queries
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="https://www.creative-tim.com/presentation">Consultation
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="https://blog.creative-tim.com">Request Demo
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="https://www.github.com/creativetimofficial">Pricing
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="https://www.creative-tim.com/bootstrap-themes/free">Customer Support
                    </a>
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                  Other Resources
                </span>
                <ul className="list-unstyled">
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                    href="https://github.com/creativetimofficial/argon-design-system/blob/master/LICENSE.md">Licence
                  </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="https://creative-tim.com/terms">Terms & Conditions
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="https://creative-tim.com/privacy">Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                        href="https://creative-tim.com/contact-us">Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-600 font-semibold py-1">
              Copyright © {new Date().getFullYear()}{" "}Qualbots-international{" "}.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}