import React, { useState } from 'react';
// import qualbotTextImage from '../images/Qual-logo.png';
import '../../../assets/css/App.css';
import { FaPhoneAlt } from "react-icons/fa";
import { MdAttachEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { SiLinkedin } from "react-icons/si";
import { ImFacebook2 } from "react-icons/im";
import { FaXTwitter } from "react-icons/fa6";

const TopBar = () => {
  const [isDarkMode] = useState(false);

  return (
    <div className={`bg-${isDarkMode ? '[#cbd5e0]' : 'inherit'} text-${isDarkMode ? 'white' : 'black'} mx-4 md:mx-12 px-4 md:px-6 py-2 md:py-3 sticky top-0 z-50 hidden md:flex`}>
      <div className='flex flex-col sm:flex-row items-center justify-between w-full'>
       
        {/* Contact Information */}
        <div className='flex flex-col sm:flex-row justify-center items-center gap-8 sm:gap-12 md:gap-16 lg:gap-20 w-full'>
          <div className='flex items-center gap-4 hover:text-[#f0a500]'>
            <FaPhoneAlt className="flex-shrink-0" />
            <p className={`text-sm md:text-base font-bold cursor-pointer ${isDarkMode ? 'text-white' : 'text-[#0b1437]'} hover:text-[#f0a500]`}>+254 716656752 / +254705583722</p>
          </div>

          <div className='flex items-center gap-4 hover:text-[#f0a500]'>
            <MdAttachEmail className="flex-shrink-0" />
            <p className={`text-sm md:text-base font-bold cursor-pointer ${isDarkMode ? 'text-white' : 'text-[#0b1437]'} hover:text-[#f0a500]`}>info@qualbots.com</p>
          </div>

          <div className='flex items-center gap-4 hover:text-[#f0a500]'>
            <FaLocationDot className="flex-shrink-0" />
            <p className={`text-sm md:text-base font-bold cursor-pointer ${isDarkMode ? 'text-white' : 'text-[#0b1437]'} hover:text-[#f0a500]`}>Kofisi Africa, 45 Salaam Building, Karen Road.</p>
          </div>
        </div>

        {/* Socials */}
        <div className='flex flex-row gap-4 md:gap-6 cursor-pointer justify-center mt-4 sm:mt-0'>
          <div className='flex items-center justify-center w-6 h-6 rounded-full bg-[#0b1437] text-white hover:bg-[#f0a500] transition-colors'>
            <SiLinkedin className='hover:text-[#0b1437] text-xs' />
          </div>
          <div className='flex items-center justify-center w-6 h-6 rounded-full bg-[#0b1437] text-white hover:bg-[#f0a500] transition-colors'>
            <FaXTwitter className='hover:text-[#0b1437] text-xs' />
          </div>
          <div className='flex items-center justify-center w-6 h-6 rounded-full bg-[#0b1437] text-white hover:bg-[#f0a500] transition-colors'>
            <ImFacebook2 className='hover:text-[#0b1437] text-xs' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
