import React, { useState } from "react";
import { FormControl, FormErrorMessage, FormLabel, Select, Textarea, Checkbox, Grid, GridItem, Button, Alert, AlertIcon, AlertDescription } from "@chakra-ui/react";
import TabMenu from "./tabs";
import './styles.css';
import { useNavigate } from "react-router-dom";
import AxiosInstance from "../../../axiosConfig";

const BotStudio = () => {
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [formData, setFormData] = useState({
    topic: '',
    keywords: '',
    category: '',
    role: '',
    language: '',
    response: '',
    act_trigger: false,
    process_flg: false,
    has_extra_ans: false,
    convo_id: '0',
    entry_status: true,
    enforces_match_flag: false,
    has_number_options: false
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ 
      ...formData, 
      [name]: type === 'checkbox' ? checked : value 
    });
  };

  const validate = () => {
    let newErrors = {};

    if (!formData.topic) {
      newErrors.topic = 'Node Topic is required';
    }
    if (!formData.category) {
      newErrors.category = 'Node Category is Required';
    }
    if (!formData.role) {
      newErrors.role = 'Node Role is Required';
    }
    if (!formData.language) {
      newErrors.language = 'Node Language is Required';
    }
    if (!formData.keywords) {
      newErrors.keywords = 'Node Keywords required';
    }
    if (!formData.response) {
      newErrors.response = 'Node Response is Required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsSubmitted(true);

      const payload = {
        act_trigger: formData.act_trigger,
        process_flg: formData.process_flg,
        has_extra_ans: formData.has_extra_ans,
        node_keywords: formData.keywords,
        node_response: formData.response,
        node_topic: formData.topic,
        node_category: formData.category,
        node_role: formData.role,
        node_language: formData.language,
        ans_in_convo_id: false,
        convo_id: formData.convo_id,
        entry_status: formData.entry_status,
        enforces_match_flag: formData.enforces_match_flag,
        has_number_options: formData.has_number_options
      };

      try {
        const response = await AxiosInstance.post('/node-creation', payload);
        
        if (response.status === 200) {
          setSuccessAlert(true);
          setTimeout(() => {
            setSuccessAlert(false);
            navigate('/admin/bot-studio/review-Node');
          }, 3000);
        } else {
          setErrorAlert(true);
          setTimeout(() => {
            setErrorAlert(false);
          }, 3000);
        }
      } catch (error) {
        console.error("Error saving node:", error);
        setErrorAlert(true);
        setTimeout(() => {
          setErrorAlert(false);
        }, 3000);
      }
    } else {
      setIsSubmitted(false);
    }
  };

  return (
    <div>
      <div className="mt-4">
        <TabMenu />
      </div>
      {successAlert && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'rgb(134 239 172)',
            width: '20%',
            padding: '10px 20px',
            borderRadius: '10px',
            zIndex: 1000,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Alert status="success" variant='subtle'>
            <AlertIcon boxSize='20px' />
            <AlertDescription className="text-xl ml-2">Success! Node created</AlertDescription>
          </Alert>
        </div>
      )}
      {errorAlert && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            background: 'rgb(255 99 71)',
            width: '20%',
            padding: '10px 20px',
            borderRadius: '10px',
            zIndex: 1000,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
          }}
        >
          <Alert status="error" variant='subtle'>
            <AlertIcon boxSize='20px' />
            <AlertDescription className="text-xl ml-2">Failed to save node. Please try again.</AlertDescription>
          </Alert>
        </div>
      )}
      
      <div className="mt-4 rounded-lg bg-white p-6 shadow-md">
        <div className="text-3xl font-bold text-navy-700 dark:text-dark">Add Node</div>

        <form onSubmit={handleSubmit}>
          <Grid
            // templateColumns="repeat(2, 1fr)"
            // templateRows="repeat(2, 1fr)"
            // gap={40}
            className="mx-5 mb-4 grid grid-cols-1 md:grid-cols-2  gap-4 sm:gap-10">
            <GridItem>
              <FormControl isInvalid={errors.topic} flex="1">
                <FormLabel htmlFor="topic" className="mb-1.5 text-lg font-medium">
                  Node Topic
                </FormLabel>
                <Select
                  id="topic"
                  name="topic"
                  value={formData.topic}
                  onChange={handleChange}
                  placeholder="Select Node Topic"
                  iconColor="transparent"
                  className="w-full rounded-lg border border-indigo-500 focus:outline-indigo-600 bg-white p-2"
                >
                  <option>General</option>
                  <option>Greetings</option>
                  <option>Business</option>
                  <option>Customer Support</option>
                </Select>
                <FormErrorMessage className="text-red-600 text-lg">{errors.topic}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl isInvalid={errors.category} flex="1">
                <FormLabel className="mb-1.5 text-lg font-medium">
                  Node Category
                </FormLabel>
                <Select
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  placeholder="Select Category"
                  iconColor="transparent"
                  className="w-full rounded-lg border  border-indigo-500 focus:outline-indigo-600 bg-white p-2"
                >
                  <option>General</option>
                  <option>Business</option>
                  <option>Customer Support</option>
                </Select>
                <FormErrorMessage className="text-red-600 text-lg">{errors.category}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl isInvalid={errors.role}>
                <FormLabel className="mb-1.5 text-lg font-medium">
                  Node Role
                </FormLabel>
                <Select
                  id="role"
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                  placeholder="Select Role"
                  iconColor="transparent"
                  className="w-full rounded-lg border  border-indigo-500 focus:outline-indigo-600 bg-white p-2"
                >
                  <option>General</option>
                  <option>Cashier</option>
                  <option>Attendant</option>
                  <option>Customer Care</option>
                </Select>
                <FormErrorMessage className="text-red-600 text-lg">{errors.role}</FormErrorMessage>
              </FormControl>
            </GridItem>

            <GridItem>
              <FormControl isInvalid={errors.language}>
                <FormLabel className="mb-1.5 text-lg font-medium">
                  Node Language
                </FormLabel>
                <Select
                  id="language"
                  name="language"
                  value={formData.language}
                  onChange={handleChange}
                  placeholder="Select Language"
                  iconColor="transparent"
                  className="w-full rounded-lg border  border-indigo-500 focus:outline-indigo-600 bg-white p-2"
                >
                  <option value='English'>English</option>
                  <option value='Swahili'>Swahili</option>
                  <option value='French'>French</option>
                  <option value='Arabic'>Arabic</option>
                  <option value='Portuguese'>Portuguese</option>
                  <option value='Kinyarwanda'>Kinyarwanda</option>
                  <option value='Luganda'>Luganda</option>
                  <option value='Zulu'>Zulu</option>
                </Select>
                <FormErrorMessage className="text-red-600 text-lg">{errors.language}</FormErrorMessage>
              </FormControl>
            </GridItem>
          </Grid>

          <div className="mx-5">
            <FormControl isInvalid={errors.keywords} flex="1">
              <FormLabel className="mb-1.5 text-lg font-medium">
                Node Keywords
              </FormLabel>
              <Textarea
                id="keywords"
                name="keywords"
                value={formData.keywords}
                onChange={handleChange}
                placeholder="Enter Node Keywords (comma-separated)"
                className="w-full rounded-lg border  border-indigo-500 focus:outline-indigo-600 bg-white p-2"
              />
              <FormErrorMessage className="text-red-600 text-lg">{errors.keywords}</FormErrorMessage>
            </FormControl>
          </div>

          <div className="mx-5 mt-4">
            <FormControl isInvalid={errors.response} flex="1">
              <FormLabel className="mb-1.5 text-lg font-medium">
                Node Response
              </FormLabel>
              <Textarea
                id="response"
                name="response"
                value={formData.response}
                onChange={handleChange}
                placeholder="Enter Node Response"
                className="w-full rounded-lg border  border-indigo-500 focus:outline-indigo-600 bg-white p-2"
              />
              <FormErrorMessage className="text-red-600 text-lg">{errors.response}</FormErrorMessage>
            </FormControl>
          </div>
          <Grid
            // templateColumns="repeat(2, 1fr)"
            // templateRows="repeat(2, 1fr)"
            // gap={40}
            className="mx-5 my-4 grid grid-cols-1 md:grid-cols-2  gap-4 sm:gap-10 ">
            <GridItem>
                <Checkbox
                  colorScheme="green"
                  size="sm"
                  id="act_trigger"
                  name="act_trigger"
                  defaultChecked
                  isChecked={formData.act_trigger}
                  onChange={handleChange}
                  className="custom-checkbox w-1/2 rounded-lg bg-blue-200 p-2 text-lg font-medium"
                  // p={[1, 2]}
                  // fontSize={['sm', 'md', 'lg']}
                  // whiteSpace='normal'
                  wordBreak='break-word'
                  // width='100%'
                  w={{ base: "100%", md: "50%", lg: "auto" }}
                >
                  Triggers an Action
                </Checkbox>
            </GridItem>
            <GridItem>
              <Checkbox
                colorScheme="green"
                size="sm"
                defaultChecked
                id="has_extra_ans"
                name="has_extra_ans"
                isChecked={formData.has_extra_ans}
                onChange={handleChange}
                className="custom-checkbox w-1/2 rounded-lg bg-blue-200 p-1.5 text-lg font-medium"
                p={[1, 2]}
                fontSize={['sm', 'md', 'lg']}
                whiteSpace='normal'
                wordBreak='break-word'
                w={{ base: "100%", md: "50%", lg: "auto" }}
              >
                Has More Responses
              </Checkbox>
            </GridItem>
            <GridItem>
              <Checkbox
                colorScheme="green"
                size="sm"
                defaultChecked
                id="enforces_match_flag"
                name="enforces_match_flag"
                isChecked={formData.enforces_match_flag}
                onChange={handleChange}
                className=" custom-checkbox w-1/2 rounded-lg bg-blue-200 p-1.5 text-lg font-medium"
                p={[1, 2]}
                fontSize={['sm', 'md', 'lg']}
                whiteSpace='normal'
                wordBreak='break-word'
                w={{ base: "100%", md: "50%", lg: "auto" }}
              >
                Force Full March
              </Checkbox>
            </GridItem>
            <GridItem>
              <Checkbox
                colorScheme="green"
                size="sm"
                defaultChecked
                id="has_number_options"
                name="has_number_options"
                isChecked={formData.has_number_options}
                onChange={handleChange}
                className="custom-checkbox w-1/2 rounded-lg p-1.5 bg-blue-200 text-lg font-medium" 
                p={[1, 2]}
                fontSize={['sm', 'md', 'lg']}
                whiteSpace='normal'
                wordBreak='break-word'
                w={{ base: "100%", md: "50%", lg: "auto" }}
              >
                Has Numbered Choices
              </Checkbox>
            </GridItem>
            <GridItem>
              <Checkbox
                colorScheme="green"
                size="sm"
                defaultChecked
                id="process_flg"
                name="process_flg"
                isChecked={formData.process_flg}
                onChange={handleChange}
                className="custom-checkbox w-1/2 rounded-lg bg-blue-200 p-1.5 text-lg font-medium"
                p={[1, 2]}
                fontSize={['sm', 'md', 'lg']}
                whiteSpace='normal'
                wordBreak='break-word'
                w={{ base: "100%", md: "50%", lg: "auto" }}
              >
                Process Flag
              </Checkbox>
            </GridItem>
            <GridItem>
              <Checkbox
                colorScheme="green"
                size="sm"
                defaultChecked
                id="entry_status"
                name="entry_status"
                isChecked={formData.entry_status}
                onChange={handleChange}
                className="custom-checkbox w-1/2 rounded-lg bg-blue-200 p-1.5 text-lg font-medium"
                p={[1, 2]}
                fontSize={['sm', 'md', 'lg']}
                whiteSpace='normal'
                wordBreak='break-word'
                w={{ base: "100%", md: "50%", lg: "auto" }}
              >
                Entry Status
              </Checkbox>
            </GridItem>
          </Grid>
          <div className="mt-8 flex justify-end">
            <Button type="submit" colorScheme="blue" className="bg-blue-200 px-8 py-2 text-lg font-medium">
              {isSubmitted ? "Submitting..." : "Submit"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BotStudio;
