import React, { useState, useRef, useEffect } from 'react';
import { IoMdArrowDropdown, IoMdMenu } from 'react-icons/io';
import '../../../assets/css/App.css';
import qualbotTextImage from '../images/Qualbots-logo.png';

const NavBar = () => {
  const [openMenu, setOpenMenu] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  // const [isDarkMode] = useState(false);

  const solutionsRef = useRef(null);
  const resourcesRef = useRef(null);
  const companyRef = useRef(null);
  const Partners = useRef(null);
  const Pricing = useRef(null);
  const RequestDemo = useRef(null);

  const handleMenuClick = (menu) => {
    setOpenMenu(openMenu === menu ? null : menu);
  };

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ![solutionsRef.current, resourcesRef.current, companyRef.current].some(
          (ref) => ref && ref.contains(event.target)
        )
      ) {
        setOpenMenu(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  return (
    <div className={`bg-transparent text-white flex items-center px-4 py-3 sticky top-0 z-50 shadow-md`}>
      <div className="flex items-center">
        <img
          src={qualbotTextImage}
          alt="QualBot logo"
          className="logo w-80 md:w-65 h-24 md:h-28 object-cover object-center"
        />
      </div>

      <button onClick={handleMobileMenuToggle} className="ml-auto md:hidden text-lg">
        <IoMdMenu className="w-8 h-8" />
      </button>

      <div className={`flex-col md:flex md:flex-row md:space-x-8 ml-auto ${isMobileMenuOpen ? 'flex' : 'hidden'} absolute md:relative top-16 md:top-0 left-0 md:left-auto md:items-center bg-transparent md:bg-transparent w-full md:w-auto`}>
        <div className="relative" ref={solutionsRef}>
          <p
            className={`text-lg font-bold cursor-pointer hover:text-[#f0a500]`}
            onClick={() => handleMenuClick('solutions')}
          >
            Home
          </p>
        </div>
        <div className="relative" ref={solutionsRef}>
          <p
            className={`text-lg font-bold cursor-pointer hover:text-[#f0a500]`}
            onClick={() => handleMenuClick('services')}
          >
            About
          </p>
        </div>
        <div className="relative" ref={solutionsRef}>
          <p
            className={`text-lg font-bold cursor-pointer hover:text-[#f0a500]`}
            onClick={() => handleMenuClick('services')}
          >
            Services
          </p>
        </div>

        <div className="relative flex flex-row items-center" ref={resourcesRef}>
          <div
            className={`flex items-center text-lg font-bold cursor-pointer hover:text-[#f0a500]`}
            onClick={() => handleMenuClick('resources')}
          >
            <p className="mb-0">Resources</p>
            <IoMdArrowDropdown className="ml-1" />
          </div>

          {openMenu === 'resources' && (
            <div className={`absolute top-full right-0 rounded-lg shadow-lg mt-2 z-50`}>
              <p className="p-3 cursor-pointer font-bold text-[#f0a500]">Developer Portal</p>
            </div>
          )}
        </div>

        <div className="relative flex flex-row items-center" ref={companyRef}>
          <div className={`flex items-center text-lg font-bold cursor-pointer hover:text-[#f0a500]`}
            onClick={() => handleMenuClick('company')}
          >
            <p className='mb-0'>Company</p>
            <IoMdArrowDropdown className="ml-1" />
          </div>

          {openMenu === 'company' && (
            <div className={`absolute top-full right-0 rounded-lg shadow-lg mt-2 z-50`}>
              <p className="p-3 cursor-pointer font-bold text-[#f0a500]">Careers</p>
            </div>
          )}
        </div>

        <div className="relative" ref={Partners}>
          <p
            className={`text-lg font-bold cursor-pointer hover:text-[#f0a500]`}
            onClick={() => handleMenuClick('partners')}
          >
            Partners
          </p>
        </div>

        <div className="relative" ref={Pricing}>
          <p
            className={`text-lg font-bold cursor-pointer hover:text-[#f0a500]`}
            onClick={() => handleMenuClick('pricing')}
          >
            Pricing
          </p>
        </div>
        <div className="relative" ref={RequestDemo}>
          <p
            className={`text-lg font-bold cursor-pointer hover:text-[#f0a500]`}
            onClick={() => handleMenuClick('requestDemo')}
          >
            Demo
          </p>
        </div>

        <div className="relative pt-2">
          <a
            href="/auth/signIn"
            className={`border border-[#0b1437] bg-white text-[#0b1437] 
                py-1 px-3 rounded-lg 
                text-sm md:text-base lg:text-lg 
                hover:bg-[#f0a500] hover:text-[#0b1437] 
                transition-colors duration-300`}
          >
            Log In
          </a>
        </div>

      </div>
    </div>
  );
};

export default NavBar;
