import React, { useEffect } from 'react';
import { FormControl, FormErrorMessage, Select, Button } from '@chakra-ui/react';
import CustomForm from './customForm';

const Channel = () => {
  // State for form data
  const [formData, setFormData] = React.useState({});
  const [errors, setErrors] = React.useState({})

  // State for select options
  const [selectData, setSelectData] = React.useState({ params: '', socials: '', variable: '' });

  // Handle form field changes
  const handleChange = (name, value) => {
    setFormData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    setErrors({...errors, [name] : ''})
  };

  
  // Handle select option changes
  const handleSelect = (e) => {
    const { name, value } = e.target;
    setSelectData(prevData => ({
      ...prevData,
      [name]: value,
    }));
    setErrors({...errors, [name] : ''})
  };

  //Handle form validation
  const validateForm = (data) => {
    let newErrors = {}

    formFields.forEach((field) => {
      if(!data[field.name]) {
        newErrors[field.name] = `${field.label} is required`
      }
    })
    if(!data.params) {
      newErrors.params = 'Parameter Type is Required'
    }
    if(!selectData.socials) {
      newErrors.socials = 'Channel is Required'
    }
    if(!selectData.variable) {
      newErrors.variable = 'Variable Name is Required'
    }

    return newErrors
  }


  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const fullForm = { ...formData, ...selectData };
    const newErrors = validateForm(fullForm)
    setErrors(newErrors)
    console.log('Form Submitted:', fullForm);
  };

  // Form fields configuration
  const formFields = [
    { name: 'pageId', label: 'Page Id or Account Id', type: 'text' },
    { name: 'desc', label: 'Description', type: 'text' }
  ];

  // Effect to log component mounting
  useEffect(() => {
    console.log('Channel component mounted');
  }, []);

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mt-4">

      <div className='ml-3'>

        <p className='text-2xl font-semibold mb-5'>Channel Configs</p>

        <form onSubmit={handleSubmit}>

          {/* Select for parameter type */}
          <FormControl isInvalid={errors.params}>

          <Select
            name='params'
            value={selectData.params}
            onChange={handleSelect}
            iconColor='transparent'
            placeholder='Choose Parameter Type'
            className='p-2 w-full md:w-1/2 lg:w-3/12 border border-indigo-500 focus:outline-indigo-600 rounded-lg bg-white mb-7'
          >
            <option value='Client Parameters'>Client Parameters</option>
            <option value='Global Parameters'>Global Parameters</option>
          </Select>

          <FormErrorMessage className="text-red-600 text-lg mb-7">{errors.params}</FormErrorMessage>

          </FormControl>
         

          {/* Select for social channel */}
          <FormControl isInvalid={errors.socials}>
          <Select
            name='socials'
            value={selectData.socials}
            onChange={handleSelect}
            iconColor='transparent'
            placeholder='Select Channel'
            className='p-2 w-full md:w-1/2 lg:w-3/12  border border-indigo-500 focus:outline-indigo-600 rounded-lg bg-white mb-7'
          >
            <option value='Website'>Website</option>
            <option value='Facebook'>Facebook</option>
            <option value='Twitter'>Twitter</option>
            <option value='Slack'>Slack</option>
            <option value='Instagram'>Instagram</option>
            <option value='WhatsApp'>WhatsApp</option>
            <option value='Telegram'>Telegram</option>
            <option value='All Channels'>All Channels</option>
          </Select>
          <FormErrorMessage className="text-red-600 text-lg mb-8">{errors.socials}</FormErrorMessage>
          </FormControl>
          

          {/* Select for variable name */}
          <FormControl isInvalid={errors.variable}>
          <Select
            name='variable'
            value={selectData.variable}
            onChange={handleSelect}
            iconColor='transparent'
            placeholder='Add Variable Name'
            className='p-2 w-full md:w-1/2 lg:w-3/12 border border-indigo-500 focus:outline-indigo-600 rounded-lg bg-white mb-7'
          >
            <option value='Facebook Page Access Token'>Facebook Page Access Token</option>
            <option value='Facebook API Access Token'>Facebook API Access Token</option>
            <option value='WhatsApp Message Number'>WhatsApp Message Number</option>
            <option value='Webhook Secret Key'>Webhook Secret Key</option>
            <option value='WhatsApp Access Token'>WhatsApp Access Token</option>
            <option value='Default Name for Sendersp'>Default Name for Senders</option>
            <option value='Packed Twitter Keys'>Packed Twitter Keys</option>
            <option value='Telegram Access Token'>Telegram Access Token</option>
          </Select>
          <FormErrorMessage className="text-red-600 text-lg mb-8">{errors.variable}</FormErrorMessage>
          </FormControl>
          

          {/* Custom form component */}
          <CustomForm
            formFields={formFields}
            onChange={handleChange}
            formData={formData}
            errors={errors}
          />

          {/* Submit button */}
          <Button
            type='submit'
            className='bg-blue-200 py-2 px-6 text-lg mb-5 rounded-lg hover:bg-blue-400'
          >
            Save Parameter
          </Button>

        </form>
      </div>
    </div>
  );
}

export default Channel;
