// Banner.jsx
import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import avatar from "assets/img/avatars/avatar1.png.jpg";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import { TiEdit } from "react-icons/ti";
import { Button } from "@chakra-ui/react";
import EditProfile from "./editProfile";
import { getItem } from "utils/localStorage";

const Banner = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    const token = getItem("access_token");
    if (token) {
      const decoded = jwtDecode(token);
      setUser({
        name: decoded.name,
        email: decoded.email,
        role: decoded.role,
        // Add more fields if necessary
      });
    }
  }, []);

  const handleOpenEditModal = () => { setModalOpen(true); };
  const handleCloseEditModal = () => { setModalOpen(false); };

  return (
    <Card extra={" w-full h-full p-[20px] bg-cover"}>
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover" 
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700">
          <img className="h-full w-full rounded-full" src={avatar} alt="Profile" />
          <div className="absolute bottom-0 right-1 p-1.5 bg-green-400 rounded-full cursor-pointer">
            <TiEdit className="" />
          </div>
        </div>
      </div>
      <div className="mt-14">
        <div className="flex flex-col justify-center items-center text-navy-700 dark:text-white">
        <p className="text-xl font-semibold">{user.name}</p>
          <p className="text-lg font-medium">{user.email}</p>
          <p className="text-lg font-medium">{user.role}</p>
          
        </div>
        <div className="rounded-xl flex flex-col justify-center items-center border bg-blue-50/40 backdrop-blur my-2 mx-auto w-1/2 pt-2 pb-3 px-3">
          <p className="text-lg font-bold mx-2">About</p>
          <p className="text-base mx-2">
           QualBot is awesome 😊. Your administrator has restricted profile edits.
          </p>
        </div>
        <EditProfile isOpen={modalOpen} onClose={handleCloseEditModal} />
      </div>
      <div className="flex justify-center items-center">
        <Button onClick={handleOpenEditModal} className="bg-blue-50 py-1.5 px-5 mt-2 text-lg rounded-lg hover:bg-blue-200">
          Edit Profile
        </Button>
      </div>
    </Card>
  );
};

export default Banner;
