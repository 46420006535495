import React, { useState } from 'react';
import { FormControl, Input, Button, FormErrorMessage, Box } from '@chakra-ui/react';
import { BsUpload } from 'react-icons/bs';

const AddBulkNodes = () => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState('');

  const validateFile = (file) => {
    if (!file) {
      return 'File is required';
    }
    const allowedTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'text/csv',
      'application/vnd.oasis.opendocument.spreadsheet',
    ];
    if (!allowedTypes.includes(file.type)) {
      return 'Unsupported File Format';
    }
    return '';
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const errorMessage = validateFile(file);
    setFile(file);
    setError(errorMessage);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorMessage = validateFile(file);
    if (errorMessage) {
      setError(errorMessage);
      return;
    }
    // Process the file
    console.log('File Uploaded:', file);
  };

  return (
    <Box className="bg-white rounded-lg shadow-md p-6 mt-4 mx-4 md:mx-0">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col md:flex-row mt-1 items-center">
          <div className="flex-1">
            <div>
              <FormControl isInvalid={!!error}>
              <Input
                type="file"
                accept=".xlsx, .xls, .csv, .ods"
                onChange={handleFileChange}
                className="text-lg ml-2 w-full"
              />
               <FormErrorMessage className="text-red-600 text-lg mb-0.5">{error}</FormErrorMessage>
              </FormControl>
              
              <div className="text-red-600 text-lg mb-0.5">{error}</div>
            </div>
          </div>
          <Button
            type="submit"
            leftIcon={<BsUpload />}
            className="bg-blue-200 py-1.5 px-6 text-lg rounded-lg hover:bg-blue-400 mt-4 md:mt-0 md:ml-4 truncate"
          >
            Upload and Process File
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default AddBulkNodes;
