import React, { useState } from 'react';
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Button,
  Box,
  Text,
  Flex,
  Checkbox,
  Select
} from '@chakra-ui/react';
import { Grid } from '@chakra-ui/react';
import axiosInstance from '../../../../axiosConfig';
import Card from 'components/card';

const ReviewNode = () => {
  const [nodeNumber, setNodeNumber] = useState('');
  const [nodeDetails, setNodeDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({ number: '' });
  const [formError, setFormError] = useState({});

  const handleRetrieveNode = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get(`/node-retrieve-${nodeNumber}`);
      setNodeDetails(response.data.Message);
    } catch (err) {
      setError('Failed to retrieve node. Please try again.');
    }
    setLoading(false);
  };

  const validate = () => {
    let newErrors = {};

    if (!formData.number) {
      newErrors.number = 'Node Number is required';
    }

    setFormError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setNodeNumber(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      handleRetrieveNode();
    }
  };

  return (
    <Card extra={"w-full h-full sm:overflow-auto px-6"}>
      <div className="bg-white rounded-lg shadow-md p-6 mt-4">
        <form onSubmit={handleSubmit}>
          <FormControl isInvalid={!!formError.number}>
            <FormLabel className='text-lg mb-1 font-normal'>Node Number</FormLabel>
            <Input
              id='number'
              name='number'
              placeholder='Enter Node Number'
              value={nodeNumber}
              onChange={handleChange}
              className='border border-indigo-500 w-1/2 p-2 rounded-lg mb-7 text-lg focus:outline-indigo-600'
            />
            <FormErrorMessage className="text-red-600 text-lg mb-4">{formError.number}</FormErrorMessage>
          </FormControl>
          <Button
            type='submit'
            className='bg-indigo-200 py-2 px-6 text-lg rounded-lg hover:bg-blue-400'
            isLoading={loading}
          >
            Retrieve Node
          </Button>
          {error && <Text color="red" mt={4}>{error}</Text>}
          {nodeDetails && (
            <Box p={4} className="bg-gray-100 rounded-lg shadow-inner mt-5">
              <Text className="text-xl font-bold mb-2 ml-4">Node Details:</Text>

              <Flex wrap="wrap" gap={{ base: 2, md: 4, lg: 6 }} >
                {/* Boolean Fields with Checkbox */}
                {[
                  { id: 'active', label: 'ACTIVE' },
                  { id: 'closed_node', label: 'CLOSED NODE' },
                  { id: 'default', label: 'DEFAULT' },
                  { id: 'enforces_match', label: 'ENFORCES MATCH' },
                  { id: 'expects_yes_no', label: 'EXPECTS YES/NO' },
                  { id: 'is_ussd_prompt', label: 'IS USSD PROMPT' },
                  { id: 'node_in_conversation', label: 'IN CONVERSATION' },
                  { id: 'presents_numbered_options', label: 'PRESENTS NUMBERED OPTIONS' },
                  { id: 'triggers_action', label: 'TRIGGERS ACTION' }
                ].map(({ id, label }) => (
                  <Box key={id} className="w-full sm:w-[30%] flex items-center space-x-2 p-2 mb-4">
                    {nodeDetails[id] ? (
                      <Checkbox id={id} isChecked={true} isReadOnly />
                    ) : (
                      <Text color="red" fontSize="lg" ml={2}>X</Text>
                    )}
                    <FormLabel htmlFor={id} className="text-lg font-medium ml-3">
                      {label}
                    </FormLabel>
                  </Box>
                ))}
                {/* Non-Boolean Fields */}
                <Grid className='grid grid-cols-1 md:grid-cols-3  gap-4'>
                  <Box className='ml-4'>
                    <FormLabel className='text-lg font-medium'>Category:</FormLabel>
                    <Select
                      value={nodeDetails.category}
                      iconColor='transparent'
                      isReadOnly
                      className='p-2 w-full  lg:w-1/2 border border-indigo-300 focus:outline-indigo-500 rounded-lg bg-white '>
                      <option value="0">Category 0</option>
                      <option value="1">Category 1</option>
                      {/* Add more categories as needed */}
                    </Select>
                  </Box>
                  <Box className=''>
                    <FormLabel className='text-lg font-medium'>Created By User:</FormLabel>
                    <Text>{nodeDetails.create_usr}</Text>
                  </Box>
                  <Box className=''>
                    <FormLabel className='text-lg font-medium'>Culture:</FormLabel>
                    <Select
                      value={nodeDetails.culture}
                      iconColor='transparent'
                      isReadOnly
                      className='p-2 w-full lg:w-1/2 border border-indigo-300 focus:outline-indigo-500 rounded-lg bg-white '>
                      <option value="en">English</option>
                      <option value="fr">French</option>
                      {/* Add more cultures as needed */}
                    </Select>
                  </Box>
                  <Box className='ml-4'>
                    <FormLabel className='text-lg font-medium'>Node Key:</FormLabel>
                    <Text>{nodeDetails.node_key}</Text>
                  </Box>
                  <Box w="30%" className=''>
                    <FormLabel className='text-lg font-medium'>Replies:</FormLabel>
                    <Text>{nodeDetails.node_replies}</Text>
                  </Box>
                  <Box className='ml-4'>
                    <FormLabel className='text-lg font-medium'>Role:</FormLabel>
                    <Select
                      value={nodeDetails.role}
                      iconColor='transparent'
                      isReadOnly
                      className='p-2 w-full  lg:w-1/2 border border-indigo-300 focus:outline-indigo-500 rounded-lg bg-white '>
                      <option value="reception">Reception</option>
                      <option value="support">Support</option>
                      {/* Add more roles as needed */}
                    </Select>
                  </Box>
                  <Box className='ml-4'>
                    <FormLabel className='text-lg font-medium'>Search Words:</FormLabel>
                    <Text>{nodeDetails.search_words}</Text>
                  </Box>
                  <Box className=''>
                    <FormLabel className='text-lg font-medium'>Topic:</FormLabel>
                    <Select
                      value={nodeDetails.topic}
                      isReadOnly
                      iconColor='transparent'
                      className='p-2 w-full lg:w-1/2 border border-indigo-300 focus:outline-indigo-500 rounded-lg bg-white '>
                      <option value="vassbot company">Vassbot Company</option>
                      {/* Add more topics as needed */}
                    </Select>
                  </Box>
                </Grid>
              </Flex>

            </Box>
          )}
        </form>
      </div>
    </Card>
  );
};

export default ReviewNode;
